
import Vue, { PropType } from "vue";

import { mdiAttachment, mdiDelete, mdiPlus } from "@mdi/js";
import http from "@/http";

import { Attachment, Building, Snackbar } from "@/interfaces";
import { DataTableHeader } from "vuetify";

export interface DataType {
  attachments: Attachment[];
  headers: DataTableHeader[];
}

export default Vue.extend({
  props: {
    building: {
      type: Object as PropType<Building>,
      required: true,
    },
  },
  data(): DataType {
    return {
      attachments: [],
      headers: [
        {
          text: "ファイル名",
          sortable: true,
          value: "name",
        },
        {
          text: "種類",
          sortable: false,
          value: "mime",
        },
        {
          text: "作成者",
          sortable: false,
          value: "creater",
        },
        {
          text: "作成日時",
          sortable: true,
          value: "created_at",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    icon() {
      return { mdiAttachment, mdiDelete, mdiPlus };
    },
    items() {
      return this.attachments.map((item) => {
        return {
          ...item,
          creater: item.user.admin ? item.user.admin.name : "--",
        };
      });
    },
  },
  mounted() {
    this.attachments = this.building.attachments;
  },
  methods: {
    async click(attachment: Attachment) {
      if (window.confirm("本当に削除しますか?") === false) {
        return;
      }

      const url = `attachments/${attachment.id}`;

      await http.delete(url);

      this.attachments = this.attachments.filter((item) => {
        return item.id !== attachment.id;
      });
    },
  },
});
